<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->

          <h2 class="brand-text text-primary ml-1">
            Benvenuto {{ emailLogged }}!
          </h2>
        </b-link>

        <b-card-text class="mb-2">
          Scegli tra le strutture in cui sei registrato!
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="Struttura"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Struttura"
                rules="required"
              >
                <v-select
                  v-model="selectedConnection"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :clearable="false"
                  :options="connectionOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-form>
        </validation-observer>
        <b-button @click="goToDashboard">
          Entra
        </b-button>
        <b-card-text class="text-center mt-2">
          <b-link @click="backToLogin">
            <feather-icon icon="ChevronLeftIcon" /> Torna al login
          </b-link>
        </b-card-text>
      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BFormGroup, BForm, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required, email } from '@validations'

export default {
  components: {
    // VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BFormGroup,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
      // connectionOptions: [{ title: 'Agenzia11', value: 'a1' }, { title: 'Agenzia2', value: 'a2' }, { title: 'Agenzia3', value: 'a3' }],
      connectionOptions: [],
      selectedConnection: {},
      prova: [],
      emailLogged: '',
    }
  },
  mounted() {
    // this.connectionOptions = sessionStorage.getItem('organization').split('§').map(v => v.split('#')).map(v => ({ title: v[1], value: v[0] }))
    this.connectionOptions = JSON.parse(sessionStorage.getItem('organization'))
    this.connectionOptions = this.connectionOptions.map(el => ({ title: el.ragione_sociale, value: el.id }))
    // this.selectedConnection = this.connectionOptions[0]
    // console.log(typeof this.connectionOptions)
    const [first] = this.connectionOptions
    this.selectedConnection = first
    this.emailLogged = sessionStorage.getItem('emailVet')
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$router.push({ name: 'auth-reset-password-v1' })
        }
      })
    },
    goToDashboard() {
      sessionStorage.setItem('activeAgency', JSON.stringify(this.selectedConnection))
      // console.log(this.selectedConnection)
      this.$router.push('/dashboard').catch(() => {})
    },
    backToLogin() {
      sessionStorage.clear()
      localStorage.clear()
      this.$router.push('/login').catch(() => {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
